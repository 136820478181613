import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';

import { Container as SharedContainer } from 'components/shared/container';
import { Title as SharedTitle } from 'components/shared/title';

import { color, fontSize, media } from '../../../_settings/variables';

export const Wrapper = styled.div`
  text-align: center;
  position: relative;
  margin-bottom: 88px;
`;

export const Container = styled(SharedContainer)``;

export const Title = styled(SharedTitle)`
  line-height: 1.3;
  margin-bottom: 16px;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
  border-bottom: 1px solid #e3e5eb;

  ${media.sm} {
    flex-direction: column;
    border-bottom: none;
    margin-bottom: 32px;
  }
`;

export const Category = styled.div`
  position: relative;
  color: rgba(44, 41, 93, 0.5);
  font-weight: 500;
  cursor: pointer;
  padding: 20px 0;
  margin: 0 32px;
  transition: all 0.2s ease-in-out;

  ${media.lg} {
    font-size: 18px;
    margin: 0 16px;
  }

  ${media.md} {
    font-size: 16px;
    margin: 0 8px;
  }

  ${media.sm} {
    font-size: 14px;
  }

  &:hover {
    color: #2c295d;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    bottom: -2px;
    background: #5d7ad8;
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
  }

  ${(p) => css`
    ${p.active &&
    css`
      color: #2c295d;

      &::after {
        transform: scaleX(1);
      }
    `}
  `}
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const CardCategory = styled.span`
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  color: #2b303d;
  width: max-content;
  text-transform: uppercase;
  background-color: #e3e5eb;
  border-radius: 4px;
  padding: 4px 12px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
`;

export const CardLogoWrapper = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const CardLogo = styled(GatsbyImage)`
  width: 100%;
  max-width: 180px;
  margin: 0 auto;
`;

export const CardText = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-top: 0;
`;

export const CardButton = styled.div`
  font-size: ${fontSize.sm};
  font-weight: 500;
  color: #798fd6;
  cursor: pointer;
  margin-top: auto;
  opacity: 0;
  transition: all 0.2s ease-in-out;
`;

export const Card = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 48px;
  border-radius: 16px;
  box-shadow: 0px 12px 42px -18px #a7aec1;
  height: 100%;
  min-height: 397px;
  border-bottom: 4px solid transparent;
  transition: all 0.2s ease-in-out;

  ${media.sm} {
    min-height: auto;
  }

  &:hover {
    border-color: #798fd6;

    ${CardButton} {
      opacity: 1;
    }
  }
`;
