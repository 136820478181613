import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { Grid, GridCell } from '../../../shared/grid';

import {
  Wrapper,
  ColumnsWrapper,
  ColumnsContainer,
  ColumnLeft,
  ColumnRight,
  Review,
  ReviewLogo,
  ReviewText,
  ReviewAuthor,
  ReviewAuthorAvatar,
  ReviewAuthorContent,
  ReviewAuthorName,
  ReviewAuthorJob,
  ColumnRightTitle,
  ColumnItemValue,
  ColumnItemTitle,
} from './customers-section-3.styles';

export const Section3 = (props) => {
  const { reviews, columnRight } = props;

  const reviewsSliderParams = {
    responsive: [
      {
        breakpoint: 991,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <ColumnsWrapper>
        <ColumnsContainer>
          <ColumnLeft>
            <Slider {...reviewsSliderParams}>
              {reviews.map((review) => (
                <Review key={review.id}>
                  <ReviewLogo
                    imgStyle={{ objectFit: 'contain' }}
                    alt={review.acf.logo.altText}
                    fluid={review.acf.logo.localFile.childImageSharp.fluid}
                  />
                  <ReviewText>{review.acf.text}</ReviewText>
                  <ReviewAuthor>
                    <ReviewAuthorAvatar
                      alt={review.acf.authorAvatar.altText}
                      fluid={review.acf.authorAvatar.localFile.childImageSharp.fluid}
                    />
                    <ReviewAuthorContent>
                      <ReviewAuthorName>{review.acf.authorFullName}</ReviewAuthorName>
                      <ReviewAuthorJob>{review.acf.authorJobTitle}</ReviewAuthorJob>
                    </ReviewAuthorContent>
                  </ReviewAuthor>
                </Review>
              ))}
            </Slider>
          </ColumnLeft>
          <ColumnRight>
            <ColumnRightTitle>{columnRight.text}</ColumnRightTitle>
            <Grid
              columns={2}
              gap={50}
              media={{
                xs: { columns: 1 },
              }}
            >
              {columnRight.items.map((item) => (
                <GridCell key={`numbers-${item.value}`}>
                  <ColumnItemValue>{item.value}</ColumnItemValue>
                  <ColumnItemTitle>{item.text}</ColumnItemTitle>
                </GridCell>
              ))}
            </Grid>
          </ColumnRight>
        </ColumnsContainer>
      </ColumnsWrapper>
    </Wrapper>
  );
};
