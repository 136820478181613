import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { VideoModal } from '../../../blocks/video-modal';

import {
  Container,
  VideoContainer,
  VideoSwiperWrapper,
  Video,
  VideoImage,
  VideoContent,
  VideoPlay,
  VideoTitle,
  VideoText,
  Pretitle,
  Title,
} from './customers-section-2.styles';

export const Section2 = (props) => {
  const { videos, pretitle, title } = props;

  const [selectedVideoUrl, setSelectedVideoUrl] = React.useState(null);

  const videoSwiperParams = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Container>
      <Pretitle>{pretitle}</Pretitle>
      <Title size="lg" as="h2">
        {title}
      </Title>
      <VideoContainer>
        <VideoSwiperWrapper>
          <Slider {...videoSwiperParams}>
            {videos.map((video) => (
              <Video key={video.id}>
                <VideoImage
                  alt={video.acf.image.altText}
                  fluid={{
                    ...video.acf.image.localFile.childImageSharp.fluid,
                    aspectRatio: 1252 / 534,
                  }}
                />
                <VideoContent>
                  <VideoPlay onClick={() => setSelectedVideoUrl(video.acf.url)} />
                  <VideoTitle>{video.acf.title}</VideoTitle>
                  <VideoText>{video.acf.text}</VideoText>
                </VideoContent>
              </Video>
            ))}
          </Slider>
        </VideoSwiperWrapper>
      </VideoContainer>

      {selectedVideoUrl && (
        <VideoModal url={selectedVideoUrl} onClose={() => setSelectedVideoUrl(null)} />
      )}
    </Container>
  );
};
