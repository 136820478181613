import * as React from 'react';

import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Container,
  Pretitle,
  Title,
  Description,
  Logos,
  Logo,
  BackgroundPlanet,
  Stripes,
} from './customers-section-1.styles';
import PlanetSvgUrl from './orbit-big-purple.url.svg';

export const Section1 = (props) => {
  const { pretitle, title, description, logos } = props;

  return (
    <Wrapper>
      <Container>
        <Pretitle>{pretitle}</Pretitle>
        <Title
          size="xxl"
          dangerouslySetInnerHTML={{
            __html: getTextWithoutParagraph(title),
          }}
        />
        <Description>{description}</Description>
        <Logos>
          {logos.map((logo) => (
            <Logo key={logo.id} alt={logo.altText} fluid={logo.localFile.childImageSharp.fluid} />
          ))}
        </Logos>
      </Container>

      <BackgroundPlanet src={PlanetSvgUrl} alt="" />
      <Stripes to="#fff" layout={2} theme="purpleBlue" />
    </Wrapper>
  );
};
