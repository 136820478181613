import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';

import { Container as SharedContainer } from 'components/shared/container';
import { Stripes as SharedStripes } from 'components/shared/stripes';
import { Title as SharedTitle } from 'components/shared/title';

import { fontSize, media } from '../../../_settings/variables';

export const Wrapper = styled.div`
  background-image: radial-gradient(circle at 50% 50%, #09011b, #0d092a);
  color: #e2c8ff;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
`;

export const Container = styled(SharedContainer)`
  padding: 146px 0 59px;

  ${media.xl} {
    padding: 146px 0 100px;
  }
`;

export const Pretitle = styled.div`
  font-weight: 500;
  font-size: ${fontSize.lg};
  margin-bottom: 24px;

  ${media.md} {
    font-size: ${fontSize.md};
  }
`;

export const Title = styled(SharedTitle)`
  line-height: 1.3;
  color: #e2c8ff;
  max-width: 1070px;
  margin: 0 auto 32px;
  font-size: 60px;
  max-width: 904px;

  ${media.md} {
    font-size: 48px;
  }

  ${media.sm} {
    font-size: 36px;
  }
`;

export const Description = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  background-image: linear-gradient(91deg, #f2e7ff, #956fa8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 50px;

  ${media.md} {
    margin-bottom: 32px;
  }
`;

export const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  row-gap: 20px;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
`;

export const Logo = styled(GatsbyImage)`
  ${(p) => css`
    width: ${p.fluid.presentationWidth / 2}px;
    height: ${p.fluid.presentationHeight / 2}px;
  `}

  ${media.md} {
    max-width: 100px;
    height: auto;
  }
`;

export const BackgroundPlanet = styled.img`
  position: absolute;
  bottom: 23px;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  max-width: 1076px;
`;

export const Stripes = styled(SharedStripes)`
  position: relative;
  z-index: 2;
`;
