import * as React from 'react';

import { getTextWithoutParagraph } from 'utils';

import { Grid, GridCell } from '../../../shared/grid';

import {
  Wrapper,
  Container,
  Title,
  CategoriesContainer,
  Category,
  Card,
  CardContent,
  CardCategory,
  CardLogoWrapper,
  CardLogo,
  CardText,
  CardButton,
} from './case-studies.styles';

const categories = {
  all: 'All Сase Studies',
  loyalty_programmes: 'Loyalty Programs',
  customer_incentives: 'Customer Incentives',
  employee_rewards: 'Employee Rewards',
  partners: 'Partners',
};

export const CaseStudies = (props) => {
  const { title, items } = props;

  const [activeCategory, setActiveCategory] = React.useState('all');

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  return (
    <Wrapper>
      <Container>
        <Title
          size="lg"
          dangerouslySetInnerHTML={{
            __html: getTextWithoutParagraph(title),
          }}
        />
        <CategoriesContainer>
          {Object.keys(categories).map((category, index) => (
            <Category
              key={index}
              active={activeCategory === category}
              onClick={() => handleCategoryClick(category)}
            >
              {categories[category]}
            </Category>
          ))}
        </CategoriesContainer>
        <Grid
          columns={4}
          gap={22}
          media={{ lg: { columns: 3 }, md: { columns: 2 }, sm: { columns: 1 } }}
          style={{ justifyContent: 'center' }}
        >
          {items
            .filter((story) => activeCategory === 'all' || story.category === activeCategory)
            .map((story, index) => (
              <GridCell key={index}>
                <Card to={story.url}>
                  <CardContent>
                    <CardCategory>{categories[story.category]}</CardCategory>
                    <CardLogoWrapper>
                      <CardLogo
                        alt={story.logo.altText}
                        fluid={story.logo.localFile.childImageSharp.fluid}
                      />
                    </CardLogoWrapper>
                    <CardText>{story.description}</CardText>
                  </CardContent>
                  <CardButton>Read the case study</CardButton>
                </Card>
              </GridCell>
            ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};
