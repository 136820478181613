import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { Container as SharedContainer } from 'components/shared/container';

import { color, fontSize, media } from '../../../_settings/variables';

export const Wrapper = styled.div`
  margin-bottom: 97px;
`;

export const Container = styled(SharedContainer)``;

export const Video = styled.div`
  display: block !important;
`;

export const ColumnsWrapper = styled.div`
  background: #defbff;
  position: relative;
  padding: 280px 0 200px;
  ${media.md} {
    padding: 100px 0 100px;
  }
  &:before {
    content: '';
    background: #00c1de;
    top: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;

    ${media.md} {
      display: none;
    }
  }
`;

export const ColumnsContainer = styled(SharedContainer)`
  display: flex;
  position: relative;
  z-index: 2;

  ${media.md} {
    display: block;
  }
`;

const Column = styled.div`
  width: 50%;
  padding: 0 50px;
  ${media.md} {
    width: 100%;
  }
`;

export const ColumnLeft = styled(Column)`
  padding-left: 0;
  color: #fff;
  ${media.md} {
    position: relative;
    padding-right: 0;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: calc(100% + 150px);
      width: calc(100vw + 101px);
      background: #00c1de;
      transform: translate(-101px, -100px);
    }
  }
  .slick-arrow {
    border: none;
    text-indent: -9999px;
    overflow: hidden;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 42 42'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='21' cy='21' r='21' fill='%23007B8E'/%3E%3Cpath stroke='%23DEFBFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M23 16l-5 5 5 5'/%3E%3C/g%3E%3C/svg%3E%0A");
    width: 42px;
    height: 42px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    transform: rotate(180deg);
    cursor: pointer;
    outline: none;
  }

  .slick-arrow.slick-prev {
    right: 54px;
    transform: none;
  }
`;

export const ColumnRight = styled(Column)`
  padding-right: 0;
  padding-top: 70px;
  ${media.md} {
    padding-top: 120px;
    padding-left: 0;
  }
  ${media.xs} {
    text-align: center;
  }
`;

export const Review = styled.div`
  outline: none;
`;

export const ReviewLogo = styled(GatsbyImage)`
  max-width: 150px;
  margin-bottom: 53px;
`;

export const ReviewText = styled.div`
  font-size: 28px;
  line-height: 1.43;
  margin-bottom: 54px;
`;

export const ReviewAuthor = styled.div`
  display: flex;
`;

export const ReviewAuthorAvatar = styled(GatsbyImage)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
`;

export const ReviewAuthorContent = styled.div`
  font-size: 14px;
`;

export const ReviewAuthorName = styled.div`
  margin-bottom: 5px;
`;

export const ReviewAuthorJob = styled.div`
  font-weight: bold;
`;

export const ColumnRightTitle = styled.div`
  color: ${color.text.primary};
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 50px;
`;

export const ColumnItemValue = styled.div`
  background-image: linear-gradient(281deg, #7f8afc, #00c1de);
  line-height: 0.92;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  margin-bottom: 20px;
`;

export const ColumnItemTitle = styled.div`
  color: ${color.text.secondary};
  line-height: 1.75;
  font-size: ${fontSize.sm};
`;
