import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Section1 } from 'components/pages/customers/section-1';
import { Section2 } from 'components/pages/customers/section-2';
import { Section3 } from 'components/pages/customers/section-3';
import { buildPageMetadata } from 'utils/data-builders';
import { extractItems } from 'utils/extract-items';

import { CaseStudies } from '../components/pages/customers/case-studies/case-studies.view';

const Customers = (props) => {
  const {
    data: { wpPage },
  } = props;
  const { acf } = wpPage;

  const section3Items = extractItems(acf, 'section3ColumnRightItem');

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header withoutActions={!acf.showHeader} theme="light" />
      <Section1
        pretitle={acf.section1Pretitle}
        title={acf.section1Title}
        description={acf.section1Description}
        logos={acf.section1Logos}
      />
      <Section2
        pretitle={acf.section2Pretitle}
        title={acf.section2Title}
        videos={acf.section3Videos}
      />
      <CaseStudies title={acf.caseStudies.title} items={acf.caseStudies.items} />
      <Section3
        reviews={acf.section3Reviews}
        columnRight={{
          text: acf.section3ColumnRightText,
          items: section3Items,
        }}
      />
      {acf.showFooter && <Footer theme="light" />}
    </Layout>
  );
};

export default Customers;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        section1Pretitle
        section1Title
        section1Description
        section1Logos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        section2Pretitle
        section2Title
        caseStudies {
          title
          items {
            category
            description
            url
            logo {
              altText
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }

        section3Videos {
          ... on WpCustomersPageVideo {
            id
            acf {
              title
              text
              url
              image {
                altText
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        section3Reviews {
          ... on WpReview {
            id
            acf {
              logo {
                altText
                localFile {
                  childImageSharp {
                    fluid(
                      maxWidth: 150
                      maxHeight: 50
                      cropFocus: WEST
                      fit: CONTAIN
                      background: "transparent"
                    ) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              text
              authorFullName
              authorJobTitle
              authorAvatar {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        section3ColumnRightText
        section3ColumnRightItem1Value
        section3ColumnRightItem1Text
        section3ColumnRightItem2Value
        section3ColumnRightItem2Text

        showHeader
        showFooter
      }
    }
  }
`;
